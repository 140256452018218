@tailwind base;

div.DraftEditor-editorContainer h1 {
  @apply text-3xl font-semibold leading-normal;
}
div.DraftEditor-editorContainer {
  @apply z-zero;
}

.notranslate.public-DraftEditor-content {
  min-height: 126px;
}

.forBottom .notranslate.public-DraftEditor-content {
  min-height: 21px;
}
