@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");

:root {
  --header-height: 3.75rem;
  --footer-height: 71px;
}

@layer base {
  html {
    font-size: 16px;
    background-color: #ffffff;
    font-feature-settings: "palt", "kern";
    color: #0f1419;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  .signInWrapper--background-image {
    background-image: url("../images/mobile_login_background.png");
  }
  @media screen and (min-width: 834px) {
    html {
      font-size: 14px;
    }
    .signInWrapper--background-image {
      background-image: url("../images/login_background.png");
    }
  }
}

@layer utilities {
  .top-header-offset {
    top: var(--header-height);
  }
  .hidden-scrollbar {
    -ms-overflow-style: none; /* IE, Edge 対応 */
    scrollbar-width: none; /* Firefox 対応 */
  }
  .hidden-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari 対応 */
    display: none;
  }
  .miniTablet-screen-height {
    height: calc(100dvh - var(--header-height));
  }
  .mobile-screen-height {
    height: calc(100dvh - var(--header-height) - var(--footer-height));
  }
}
